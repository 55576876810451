/* div {
  border: 1px solid black;
} */

.App {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Display {
  height: 90vh;
  width: 50ch;
  display: grid;
  grid-template-rows: 15% 1fr;
}

.Page {
  padding-bottom: 1rem;
}

.NavBarWrap {
  grid-row: 1;
  position: fixed;
  width: 50ch;
  background-color: white;
  padding-top: 1.25rem;
  padding-bottom: 1rem;
}

.NavBarDesktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.RoutesWrap {
  grid-row: 2;
  padding-top: 3rem;
}

.NavBarMobile {
  display: none;
}

.Logo {
  height: 3rem;
  width: 3rem;
  filter: invert(75%);
  margin-right: 1rem;
}

.Logo:hover {
  filter:invert(0%);
}

.LinksWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavLink {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.NavLink, .ResumeButton {
  text-decoration: none;
  color: inherit;
}

.NavLink:hover {
  text-decoration: underline;
}

.ResumeButton {
  border: 1px solid black;
  border-radius: 0.6rem;
  padding: 0.4rem;
}

.ResumeButton:hover {
  background-color: black;
  color: white;
}

.Hello {
  font-family: 'Sacramento', cursive;
  font-size: 6rem;
  text-align: left;
  margin: 0;
  margin-bottom: -1rem;
}

.BannerTitle {
  font-weight: 700;
  font-size: 1.7rem;
}

.BannerText {
  margin-top: 1rem;
}

/* .BannerTitle, .BannerText {
  text-align: justify;
} */

.Screen {
  width: 60%;
  margin-top: 2rem;
}

.PageTitle {
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.SkillCategory {
  font-weight: 600;
  height: 3.5rem;
}

.SkillCard {
  width: 42%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed black;
  border-right: 1px dashed black;
  text-align: center;
  border-radius: 25px;
  padding: 0.5rem;
}

.SkillList {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.ProjectCard {
  text-align: center;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  margin-bottom: 0.25rem;
}

.ProjectTitle {
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.ProjectLinks {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ProjectLink {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.ProjectDescription {
  text-align: left;
}

a {
  color: black;
}

.ContactList {
  display: flex;
  flex-direction: column;
}

.ContactItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.ContactInfo {
  width: 20ch;
}

.ContactIcon > svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.ContactLink {
  text-decoration: none;
}

.ContactLink:hover {
  text-decoration: underline;
}

@media (max-width: 700px) {

  .Display {
    width: 90vw;
  }

  .NavBarWrap {
    width: 90vw;
  }

  .NavBarDesktop {
    display: none;
  }

  .NavBarMobile {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .NavMenu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .NavBarIcon {
    height: 2.5rem;
    width: 2.5rem;
  }

  .Logo {
    filter: invert(0%);
  }

  .NavDropDown {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 1px dashed black;
    padding: 1rem;
  }

  .MobileLink {
    margin: 0.5rem 0;
    padding-right: 0.5rem;
    text-decoration: none;
    color: inherit;
  }

  .Hello {
    font-size: 5rem;
  }

  .Screen {
    width: 80%;
  }
}


/* 
.NavBar {
  display: none;
} */

/*
.LeftPane, .RightPane {
  display: flex;
  align-items: center;
  height: 90vh;
}

.LeftPane {
  grid-column-start: 1;
  grid-column-end: 2;
  justify-content: flex-end;
}

.RightPane {
  grid-column-start: 3;
  grid-column-end: 4;
  justify-content: flex-start;
}

.MidPane {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
}

.MenuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.link {
  text-decoration: none;
  padding: 10px;
  margin-right: 5px;
}

.link:visited {
  color: black;
}

.slideText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resumeButton {
  border: black solid 1px;
  border-radius: 10px;
  padding: 7px;
}

.menuText {
  font-size: 20px;
  font-weight: 400;
}

.contactInfoItem {
  font-size: 20px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-top: 0px;
  padding-top: 0px;
}

.subTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.center {
  text-align: center;
}

.text {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.skillCard {
  width: 20vw;
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed black;
  border-right: 1px dashed black;
  text-align: center;
  border-radius: 25px;
}

.allSkillCards {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.allProjectCards {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
}

.projectTitle {
  font-size: 20px;
  font-weight: 600;
}

.projectCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  text-align: justify;
}

.hello {
  font-family: 'Sacramento', cursive;
  font-size: 100px;
  text-align: left;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.myLogo > img {
  margin: -12px;
  margin-right: 10px;
  padding: 0px;
  height: 40px;
  width: 40px;
  filter: invert(75%);
}

.HeroBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 70vh;
}

.contactList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 30px;
  padding-bottom: 0px;
}

.contactItem {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.contactInfo, .contactIcons {
  margin: 0;
  padding: 0;
}

.contactIcons > div {
  padding-top: 7px;
}

.contactInfo > div {
  padding-left: 20px;
}

.contactInfoItem {
  margin: 0;
  height: 40px;
}

.contactInfo {
  text-align: left;
  font-size: 17px;
  font-weight: 400;
}

.contactIcon {
  display: flex;
}

.bulletItem {
  margin: 0px;
  padding: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.myLogo > img:hover {
  filter: invert(0%);
}

.resumeButton:hover {
  text-decoration: none;
  background-color: black;
  color: white;
}

.projectTitle {
  text-align: center;
}

.screen {
  width: 300px;
}

.projectLinks {
  display: flex;
  flex-direction: row;
}

.projectLink > a {
  text-decoration: underline;
}

.HeroBanner > .text {
  margin-top: 20px;
  padding: 0;
}
*/